

.pre-cart-wrapper {
  width: 100%;
  max-width: 400px;

  @media screen and (max-width: 767px) {
    max-width: 767px;
  }
}


.pre-cart-action-fixed {
  //position: fixed;
  //bottom: 16px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

}

.pre-cart-action-wrapper {
  width: 1000px;
  box-sizing: border-box;
  flex: 1;
}
